<template>
  <div>
      详情11111
  </div>
</template>

<script>
export default {
  // name:'detail',
  // data(){
  //     return{
  //         aaa:111
  //     }
  // }
}
</script>

<style>

</style>
